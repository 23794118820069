<template>
  <div v-if="authStore.user" id="sidebarMenu"
       class="col-auto flex-column flex-shrink-0 bg-light sidebar collapse show p-0 h-100">
    <ul class="nav flex-column mb-auto h-100 overflow-auto text-nowrap pe-3">
      <li class="nav-item">
        <router-link :class="{ active: isComp('Home') }" :to="{ name: 'Home' }" class="nav-link">
          <i class="fs-5 bi-house"></i><span class="ms-2">Home</span>
        </router-link>
      </li> <!-- Home -->
      <li class="nav-item">
        <!--        <router-link :class="{active : isComp('Calendar')}" class="nav-link" to="/calendar">-->
        <!--          <i class="fs-5 bi-calendar"></i><span class="ms-2">Calendar</span>-->
        <!--        </router-link>-->
        <div class="nav-link disabled"><i class="fs-5 bi-calendar"></i><span class="ms-2">Calendar</span></div>
      </li> <!-- Calendar -->
      <li class="nav-item ">
        <div class="btn-group">
          <router-link :class="{ active: isComp('Product') }" :to="{ name: 'ProductList' }" class="nav-link pe-2">
            <svg id="Layer_1" fill="#000000" height="17.5px" stroke="#000000" version="1.1"
                 viewBox="0 0 511.514 511.514"
                 width="17.5px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="SVGRepo_bgCarrier" stroke-width="0"/>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
              <g id="SVGRepo_iconCarrier"><g><g><path d="M499.277,362.53c-0.32-0.213-0.64-0.427-0.96-0.64L266.53,233.57c0.427-14.4,2.347-18.133,2.773-18.88 c2.347-1.173,4.693-2.24,7.04-3.307c21.333-10.027,43.413-20.373,43.413-58.24c0-34.56-28.693-62.613-63.893-62.613 s-63.893,28.053-63.893,62.613h21.333c0-22.72,19.093-41.28,42.667-41.28c23.573,0,42.667,18.56,42.667,41.28 c0,24.32-11.093,29.547-31.147,38.933c-2.347,1.067-4.8,2.24-7.253,3.413c-11.627,5.653-14.293,21.013-14.827,37.867 L13.197,361.89c-0.32,0.213-0.64,0.427-0.96,0.64c-10.773,7.573-15.04,23.04-10.347,37.653c4.053,12.8,13.867,20.8,25.6,20.8 h456.533c11.733,0,21.547-8,25.6-20.907C514.317,385.57,510.05,370.104,499.277,362.53z M489.357,393.677 c-0.213,0.64-2.027,5.973-5.333,5.973H26.957c-3.307,0-5.12-5.333-5.333-5.973c-1.707-5.227-0.747-11.093,2.027-13.547 l231.787-128.32l231.787,128.32C490.104,382.477,490.957,388.45,489.357,393.677z"/> </g> </g> </g>
            </svg>
            <span class="ms-2">Products</span>
          </router-link>
        </div>
        <div id="product-collapse" class="collapse show">
          <ul class="list-unstyled ms-2">
            <li>
              <div class="btn-group">
                <router-link :class="{ active: isComp('Variation') }" :to="{ name: 'VariationList' }"
                             class="nav-link pe-2">
                  <i class="fs-5 bi-palette"></i><span class="ms-2">Variations</span>
                </router-link>
              </div>
              <div id="variation-collapse" class="collapse show">
                <ul class="list-unstyled ms-2">
                  <li>
                    <router-link :class="{ active: isComp('Unit') }" :to="{ name: 'UnitList' }" class="nav-link">
                      <i class="fs-5 bi-hash"></i><span class="ms-2">Units</span>
                    </router-link>
                  </li>
                </ul>
              </div> <!-- Units -->
            </li>
          </ul>
        </div> <!-- Variations -->
      </li> <!-- Products -->
      <li class="nav-item">
        <a :class="{ active: route.path.startsWith('/misc') }" aria-expanded="false"
           class="nav-link dropdown-toggle collapsed" data-bs-target="#misc-collapse" data-bs-toggle="collapse"
           role="button">
          <i class="fs-5 bi-layout-wtf"></i><span class="ms-2">Misc</span>
        </a>
        <div id="misc-collapse" :class="{ show: route.path.startsWith('/misc') }" class="collapse">
          <ul class="list-unstyled ms-3 pe-1">
            <li>
              <router-link :class="{ active: isComp('Brand') }" :to="{ name: 'BrandList' }"
                           class="dropdown-item nav-link">Brands
              </router-link>
            </li> <!-- Brands -->
            <li>
              <router-link :class="{ active: isComp('Attribute') }" :to="{ name: 'AttributeList' }"
                           class="dropdown-item nav-link">Attributes
              </router-link>
            </li> <!-- Attributes -->
            <li>
              <router-link :class="{ active: isComp('Option') }" :to="{ name: 'OptionList' }"
                           class="dropdown-item nav-link">Options
              </router-link>
            </li> <!-- Options -->
            <li>
              <router-link :class="{ active: isComp('Tag') }" :to="{ name: 'TagList' }" class="dropdown-item nav-link">
                Tags
              </router-link>
            </li> <!-- Tags -->
            <li>
              <router-link :class="{ active: isComp('Upload') }" :to="{ name: 'UploadList' }"
                           class="dropdown-item nav-link">Uploads
              </router-link>
            </li> <!-- Uploads -->
            <li>
              <router-link :class="{ active: isComp('Location') }" :to="{ name: 'LocationList' }"
                           class="dropdown-item nav-link">Locations
              </router-link>
            </li> <!-- Locations -->
            <li>
              <router-link :class="{ active: isComp('Order Status') }" :to="{ name: 'Order StatusList' }"
                           class="dropdown-item nav-link">Order Status
              </router-link>
            </li> <!-- Order Statuses -->
            <li>
              <router-link :class="{ active: isComp('Unit Status') }" :to="{ name: 'Unit StatusList' }"
                           class="dropdown-item nav-link">Unit Status
              </router-link>
            </li> <!-- Unit Statuses -->
            <li>
              <router-link :class="{ active: isComp('Unit History') }" :to="{ name: 'Unit HistoryList' }"
                           class="dropdown-item nav-link">Unit History
              </router-link>
            </li> <!-- Unit History -->
          </ul>
        </div>
      </li> <!-- Misc -->
      <li class="nav-item">
        <router-link :class="{ active: isComp('Unit', {}, { status: [26, 25, 24] }) }"
                     :to="{ name: 'UnitList', query: { status: [26, 25, 24], page_size:64 } }" class="nav-link">
          <i class="fs-5 bi-hash"></i><span class="ms-2">Check-In</span>
        </router-link>
      </li> <!-- Units Check-In  -->
      <li class="nav-item">
        <router-link :class="{ active: isComp('Unit', {}, { status: 17 }) }"
                     :to="{ name: 'UnitList', query: { status: 17, page_size:64 } }"
                     class="nav-link">
          <i class="fs-5 bi-hash"></i><span class="ms-2">Renting </span>
        </router-link>
      </li> <!-- Units Renting  -->
      <li class="nav-item">
        <router-link :class="{ active: isComp('Unit', {}, { status: [4, 5,6] }) }"
                     :to="{ name: 'UnitList', query: { status: [4, 5,6], page_size:64 } }" class="nav-link">
          <i class="fs-5 bi-hash"></i><span class="ms-2">Repairing</span>
        </router-link>
      </li> <!-- Units Repairing  -->
      <li class="nav-item">
        <router-link :class="{ active: isComp('Unit', {}, { status: [8, 21] }) }"
                     :to="{ name: 'UnitList', query: { status: [8, 21], page_size:64 } }" class="nav-link">
          <i class="fs-5 bi-hash"></i><span class="ms-2">Dry-Cleaning</span>
        </router-link>
      </li> <!-- Units Dry-Cleaning  -->
      <li class="nav-item">
        <div class="btn-group">
          <router-link :class="{ active: isComp('OperationBooking') }" :to="{ name: 'OperationList' }"
                       class="nav-link pe-2">
            <i class="fs-5 bi-tools"></i><span class="ms-2">Operations |</span>
          </router-link>
          <a :class="{ active: isComp('OperationBooking') }" aria-expanded="false" aria-haspopup="true"
             class="nav-link dropdown-toggle dropdown-toggle-split ps-0 collapsed" data-bs-target="#operation-collapse"
             data-bs-toggle="collapse" role="button" style="padding-top: 0.9rem">
          </a>
        </div>
        <div id="operation-collapse" :class="{ show: (isComp('OperationBooking') || isComp('OperationSale')|| isComp('Operation')) }"
             class="collapse">
          <ul class="list-unstyled ms-3 pe-1">
            <li v-for="operation in sale_operations">
              <router-link :class="{ active: isComp('OperationSale', { pk: operation.id }) }"
                           :to="{ name: 'OperationSale', params: { pk: operation.id } }"
                           class="dropdown-item nav-link">
                {{ operation.name }}
                <template v-if="operation?.operation_sales !== undefined">
                  <span v-if="typeof operation?.operation_sales === 'number'" class="badge rounded-pill text-bg-dark">
                    {{ operation.operation_sales }}
                  </span>
                  <template v-else-if="Object.entries(operation?.operation_sales).length">
                  <span v-for="(count,priority) in operation?.operation_sales"
                        :key="priority" :class="priority.replace('text-', 'text-bg-')"
                        class="ms-2 badge rounded-pill">{{ count }}
                  </span>
                  </template>
                  <!--                  <span v-else class="ms-2 badge rounded-pill bg-dark">0</span>-->
                </template>
                <span v-else aria-hidden="true" class="p-1 spinner-border spinner-border-sm ms-auto"></span>
              </router-link>
            </li> <!-- Booking Operation -->
            <li v-for="operation in booking_operations">
              <router-link :class="{ active: isComp('OperationBooking', { pk: operation.id }) }"
                           :to="{ name: 'OperationBooking', params: { pk: operation.id } }"
                           class="dropdown-item nav-link">
                {{ operation.name }}
                <template v-if="operation?.operation_bookings !== undefined">
                  <span v-if="typeof operation?.operation_bookings === 'number'"
                        class="badge rounded-pill text-bg-dark">
                    {{ operation.operation_bookings }}
                  </span>
                  <template v-else-if="Object.entries(operation?.operation_bookings).length">
                  <span v-for="(count,priority) in operation?.operation_bookings"
                        :key="priority" :class="priority.replace('text-', 'text-bg-')"
                        class="ms-2 badge rounded-pill">{{ count }}
                  </span>
                  </template>
                  <!--                  <span v-else class="ms-2 badge rounded-pill bg-dark">0</span>-->
                </template>
                <span v-else aria-hidden="true" class="p-1 spinner-border spinner-border-sm ms-auto"></span>
              </router-link>
            </li> <!-- Operations -->
          </ul>
        </div>
      </li> <!-- Booking Ops -->
      <li class="nav-item">
        <div class="btn-group">
          <router-link :class="{ active: isComp('Order') }" :to="{ name: 'OrderList' }" class="nav-link pe-2">
            <i class="fs-5 bi-cart"></i><span class="ms-2">Orders |</span>
          </router-link>
          <a :class="{ active: isComp('Order') }" aria-expanded="false" aria-haspopup="true"
             class="nav-link dropdown-toggle dropdown-toggle-split ps-0 collapsed" data-bs-target="#order-collapse"
             data-bs-toggle="collapse" role="button" style="padding-top: 0.9rem">
          </a>
        </div>
        <div id="order-collapse" :class="{ show: route.path.startsWith('/order') }" class="collapse">
          <ul class="list-unstyled ms-2">
            <li>
              <router-link :class="{ active: isComp('Booking') }" :to="{ name: 'BookingList' }" class="nav-link">
                <i class="fs-5 bi-book"></i><span class="ms-3">Bookings</span>
              </router-link><!-- Bookings -->
            </li>
            <li>
              <router-link :class="{ active: isComp('Sale') }" :to="{ name: 'SaleList' }" class="nav-link">
                <i class="fs-5 bi-bag"></i><span class="ms-3">Sales</span>
              </router-link><!-- Sales -->
            </li>
          </ul>
        </div>
      </li> <!-- Orders -->
      <li class="nav-item">
        <div class="btn-group">
          <router-link :class="{ active: isComp('Customer') }" :to="{ name: 'CustomerList' }" class="nav-link pe-2">
            <i class="fs-5 bi-person-badge"></i><span class="ms-2">Customers |</span>
          </router-link>
          <a :class="{ active: isComp('Customer') }" aria-expanded="false" aria-haspopup="true"
             class="nav-link dropdown-toggle dropdown-toggle-split ps-0 collapsed" data-bs-target="#customer-collapse"
             data-bs-toggle="collapse" role="button" style="padding-top: 0.9rem">
          </a>
        </div>
        <div id="customer-collapse" :class="{ show: route.path.startsWith('/customer') }" class="collapse">
          <ul class="list-unstyled ms-2">
            <li>
              <router-link :class="{ active: isComp('Address') }" :to="{ name: 'AddressList' }" class="nav-link">
                <i class="fs-5 bi-geo-alt"></i><span class="ms-3">Addresses</span>
              </router-link>
            </li><!-- Addresses -->
          </ul>
        </div>
      </li> <!-- Customers -->
      <li class="nav-item mt-auto">
        <a :class="{ active: isComp('/archive') }" aria-expanded="false" class="nav-link dropdown-toggle collapsed"
           data-bs-target="#archive-collapse" data-bs-toggle="collapse" role="button">
          <i class="fs-5 bi-trash-fill"></i><span class="ms-2">Archived</span>
        </a>
        <div id="archive-collapse" :class="{ show: route.path.startsWith('/archive') }" class="collapse" style="">
          <ul class="list-unstyled ms-3 pe-1">
            <li>
              <router-link :class="{ active: isComp('Product') }"
                           :to="{ name: 'ProductList', query: { archived: true } }"
                           class="dropdown-item nav-link"> Products
              </router-link>
            </li>
            <li>
              <router-link :class="{ active: isComp('Variation') }"
                           :to="{ name: 'VariationList', query: { archived: true } }"
                           class="dropdown-item nav-link"> Variations
              </router-link>
            </li>
            <li>
              <router-link :class="{ active: isComp('Unit') }" :to="{ name: 'UnitList', query: { archived: true } }"
                           class="dropdown-item nav-link"> Units
              </router-link>
            </li>
          </ul>
        </div>
      </li> <!-- Archived -->
    </ul>
    <hr>
    <div v-if="authStore.user" class="dropdown px-3 pb-3">
      <a id="dropdownUser" aria-expanded="false"
         class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle " data-bs-toggle="dropdown"
         href="#">
        <!--                <img src="https://github.com/mdo.png" alt="" class="rounded-circle" width="32" height="32">-->
        <strong>{{ authStore.user.username }}</strong>
      </a>
      <ul aria-labelledby="dropdownUser" class="dropdown-menu text-small shadow">
        <!--        <li>-->
        <!--          <router-link class="dropdown-item disabled" to="/"><span>Settings</span></router-link>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <router-link class="dropdown-item disabled" to="/"><span>Profile</span></router-link>-->
        <!--        </li>-->
        <!--        <li>-->
        <!--          <hr class="dropdown-divider">-->
        <!--        </li>-->
        <li>
          <a class="dropdown-item" href="#" @click="authStore.logout()">Sign out</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref, watch} from "vue";
import {RouterLink, useRoute} from "vue-router";
import {useAppStore, useAuthStore} from "@/stores";
import {OperationService} from "@/services";

const authStore = ref(useAuthStore());
const appStore = ref(useAppStore());
const route = useRoute();
const booking_operations = ref([]);
const sale_operations = ref([]);

onMounted(() => {
  handleOperationExpanded()
});

const isComp = (name, params = {}, query = {}) => {
  if (!route.name) return false;
  let conditions = []
  if (Object.entries(query).length) {
    conditions.push(Object.entries(query).every(([key, value]) => route.query[key]?.toString() === value.toString()))
  }
  if (Object.entries(params).length) {
    conditions.push(Object.entries(params).every(([key, value]) => route.params[key]?.toString() === value.toString()))
  }
  conditions.push([`${name}`, `${name}List`, `${name}Update`, `${name}Create`].includes(route.name))
  let result = conditions.every(e => e)
  return result
}

const handleOperationExpanded = () => {
  const accordionItem = document.querySelector('#operation-collapse');
  if (accordionItem) accordionItem.addEventListener('show.bs.collapse', loadOperations);
}

const loadOperations = async () => {
  if (sale_operations.value.length === 0) {
    try {
      const response = await OperationService.list({type: 'sale'});
      sale_operations.value = response.data;
    } catch (error) {
      appStore.value.addToast('Error', error, 'danger')
    }
  }
  for (const operation of sale_operations.value) {
    operation.operation_sales = undefined;
  }
  try {
    const response = await OperationService.listSale();
    sale_operations.value = response.data;
  } catch (error) {
    appStore.value.addToast('Error', error, 'danger')
  }
  if (booking_operations.value.length === 0) {
    try {
      const response = await OperationService.list({type: 'rent'});
      booking_operations.value = response.data;
    } catch (error) {
      appStore.value.addToast('Error', error, 'danger')
    }
  }
  for (const operation of booking_operations.value) {
    operation.operation_bookings = undefined;
  }
  try {
    const response = await OperationService.listBooking();
    booking_operations.value = response.data;
  } catch (error) {
    appStore.value.addToast('Error', error, 'danger')
  }
}

watch(() => appStore.value.loadSideBar, (newValue) => {
  if (newValue) {
    loadOperations()
    appStore.value.resetSidebarLoad();
  }
});

</script>
<style>
/*
 * Sidebar
 */

.sidebar {
  z-index: 100;
  /* Behind the navbar */
  box-shadow: inset -2px -2px 0 rgba(0, 0, 0, .1);
  display: flex;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #000000;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.sidebar .nav {
  flex-wrap: nowrap;
}
</style>
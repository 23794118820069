<template>
  <Datepicker
      v-model="dates"
      :disabled-dates="disabledDates"
      :enable-time-picker="false"
      :highlight="{dates: disabledDates, weekdays: [0], options: { highlightDisabled: true } }"
      :inline="inline"
      :max-date="addDays(new Date(), 180)"
      :min-date="addDays(new Date(), -180)"
      :range="{ autoRange: 3, noDisabledRange: true}"
      format="dd/MM/yyyy"
      locale="es"
      prevent-min-max-navigation
      six-weeks="fair">
  </Datepicker>
</template>

<script setup>
import addDays from "date-fns/addDays";
import {computed, ref} from "vue";

const props = defineProps({
  dates: {
    required: false,
    type: Array,
    default: () => [],
  },
  disabledDates: {
    required: false,
    type: Array,
    default: () => [],
  },
  inline: {
    required: false,
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['update:dates', 'update:disabledDates']);
const dates = computed({
  get() {
    return props.dates;
  },
  set(value) {
    emit('update:dates', value);
  },
});
const disabledDates = computed({
  get() {
    return props.disabledDates;
  },
  set(value) {
    emit('update:disabledDates', value);
  },
});

const today = new Date();
const markers = ref([
  {
    date: addDays(new Date(), 2),
    type: 'line',
    tooltip: [
      {text: 'First tooltip'},
      {text: 'Second tooltip', color: 'yellow'},
    ],
  },
  {
    date: addDays(new Date(), 3),
    type: 'dot',
    color: 'yellow',
  },
]);
const highlightedDates = ref([
  addDays(new Date(), 1),
  addDays(new Date(), 2),
  addDays(new Date(), 3),
])
</script>

<style scoped>
*::before,
*::after {
  position: absolute;
}

.dp__cell_highlight {
  background-color: lightcoral;
}
</style>
<template>
  <span v-tooltip:top='loadTooltip' @mouseenter="updateToolipImg(src,title)">
    <slot name="title"></slot>
  </span>
</template>
<script setup>
import {ref} from "vue";
import {resImg} from "@/assets/extra";

const props = defineProps({
  src: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: false,
    default: ""
  }
})
const loaded = ref(false);
const tooltipImg = ref(null);
const loadTooltip = () => {
  return tooltipImg.value;
}
const updateToolipImg = (src, title) => {
  let scaledImg = resImg(src, '-267x400');
  tooltipImg.value = `<img src="${scaledImg}" alt="${title}"><div>${title}</div>`;
  loaded.value = true;
}
</script>
<style scoped>
</style>
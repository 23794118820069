<template>
  <div class="hstack gap-1">
    <button :class="{active : Object.keys(filter_params).length>0}" aria-controls="offcanvasFilters"
            class="btn btn-sm btn-outline-dark" data-bs-target="#offcanvasFilters" data-bs-toggle="offcanvas">
      <i class="bi bi-funnel-fill"></i> {{ Object.keys(filter_params).length > 0 ? 'Edit' : 'Add' }} Filters
    </button>
    <button v-if="Object.keys(filter_params).length > 0" class="btn btn-sm btn-outline-danger"
            @click="resetApplyFilters">
      Reset Filters
    </button>
  </div>
  <div id="offcanvasFilters" aria-labelledby="offcanvasFiltersLabel" class="offcanvas offcanvas-end"
       data-bs-scroll="true"
       tabindex="-1">
    <div class="offcanvas-header">
      <h5 id="offcanvasFiltersLabel" class="offcanvas-title">Filters</h5>
      <button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
    </div>
    <div class="offcanvas-body">
      <form class="h-100 d-flex flex-column" @submit.prevent="applyFilters">
        <div class="container-fluid overflow-auto">
          <slot/>
        </div>
        <div class="hstack gap-3 mt-3">
          <input class="btn btn-dark" data-bs-dismiss="offcanvas" type="submit" value="Apply Filters">
          <div class="vr"></div>
          <div class="btn btn-outline-danger" type="submit" @click="resetFilters">
            Reset
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useRouter} from "vue-router";

const router = useRouter();
const props = defineProps({
  params: {
    type: Object,
    required: false,
    default: {}
  },
});
const filters = defineModel('filters');
const params = computed(() => props.params);

const applyFilters = async () => {
  let activeFilters = {};

  const recurApply = (obj, forceKey) => {
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        if (value.length !== 0) {
          if (!activeFilters[forceKey || key]) {
            activeFilters[forceKey || key] = [];
          }
          activeFilters[forceKey || key].push(...value);
        }
      } else if (['string', 'number'].includes(typeof value)) {
        if (value !== "") {
          activeFilters[forceKey || key] = value;
        }
      } else if (['boolean'].includes(typeof value)) {
        let formField = getFormField(key);
        let control = formField.dataset?.default
        if (control === undefined) {
          if (!formField.indeterminate) {
            activeFilters[forceKey || key] = formField.checked;
          }
        } else {
          if (formField.indeterminate) {
            activeFilters[forceKey || key] = "none";
          } else if (control === "false" && formField.checked === true) {
            activeFilters[forceKey || key] = formField.checked;
          } else if (control === "true" && formField.checked === false) {
            activeFilters[forceKey || key] = formField.checked;
          }
        }

      } else {
        recurApply(value, key)
      }
    }
  }
  recurApply(filters.value)
  delete params.value.page
  // get params leaving only page_size if exists
  const filteredParams = Object.keys(params.value).reduce((object, key) => {
    if (key === 'page_size' || key === 'ordering') {
      object[key] = params.value[key];
    }
    return object
  }, {})
  const newParams = {
    ...filteredParams,
    ...activeFilters
  };
  await router.push({query: newParams});
};

const getFormField = (key) => {
  return document.querySelector(`input[type="checkbox"][name="${key}"]`);
}

const cleanSearch = async () => {
  if (filters.value.search) {
    filters.value.search = '';
  }
}

const resetFilters = async () => {
  const recurReset = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (key === 'search') continue;
      if (Array.isArray(value)) {
        obj[key] = [];
      } else if (typeof value === 'string') {
        obj[key] = "";
      } else if (typeof value === 'boolean') {
        obj[key] = false;
        let formField = getFormField(key);
        if (formField.dataset?.default === undefined) {
          formField.indeterminate = true;
          formField.checked = false;
          formField.currentIndex = 0;
          obj[key] = '';
        } else if (formField.dataset?.default === "true") {
          formField.indeterminate = false;
          formField.checked = true;
          formField.currentIndex = 2;
          obj[key] = true;
        } else if (formField.dataset?.default === "false") {
          formField.indeterminate = false;
          formField.checked = false;
          formField.currentIndex = 1;
          obj[key] = false;
        }
      } else {
        recurReset(value);
      }
    }
  }
  recurReset(filters.value)
  console.log(filters.value)
};

const resetApplyFilters = async () => {
  await cleanSearch();
  await resetFilters();
  await applyFilters();
};

const filter_params = computed(() => {
  return Object.fromEntries(Object.entries(params.value).filter(key => key[0] in filters.value));
})

function getFiltersFromParams() {
  for (const [key, value] of Object.entries(filter_params.value)) {
    if (Array.isArray(filters.value[key])) {
      if (Array.isArray(value)) {
        filters.value[key] = value;
      } else {
        filters.value[key] = [value];
      }
    } else if (["false", "true", 'none'].includes(value)) {
      let values = [false, true, 'none'];
      let formField = getFormField(key);
      if (formField && formField.indeterminate !== undefined) {
        formField.indeterminate = value === 'none';
        let currentIndex = values.indexOf(value);
        formField.checked = value
        formField.currentIndex = (currentIndex + 1) % values.length;
      }
      filters.value[key] = value;
    } else if (['string', 'number'].includes(typeof value)) {
      filters.value[key] = value;
    } else {
      if (Array.isArray(value)) {
        filters.value[key] = {
          ...filters.value[key], ...value.reduce((obj, item) => {
            for (const [rkey, rvalue] of Object.entries(filters.value[key])) {
              if (item.includes(rkey)) {
                if (!obj[rkey]) {
                  obj[rkey] = [];
                }
                obj[rkey].push(item);
              }
            }
            return obj;
          }, {})
        };
      } else {
        for (const [rkey, rvalue] of Object.entries(filters.value[key])) {
          if (value.includes(rkey)) {
            filters.value[key][rkey] = [value];
          }
        }
      }
    }
  }
  if (Object.keys(filter_params.value).length) console.log(filter_params.value)
}

onMounted(() => {
  getFiltersFromParams();
})
</script>

<style scoped>
</style>
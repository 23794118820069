import {DefaultService} from "@/services/default";


export class UnitService extends DefaultService {
    static resourceURL = 'unit/'

    static unavailability(id, params = {}, headers = {}) {
        return this.request(`${id}/unavailability/`, 'GET', null, params, headers);
    }

    static availability(id, params = {}, headers = {}) {
        return this.request(`${id}/availability/`, 'GET', null, params, headers);
    }

    static changeField(data, params = {}, headers = {}) {
        return this.request('change-field/', 'PATCH', data, params, headers);
    }

    static search(data, params = {}, headers = {}) {
        return this.request('search/', 'PATCH', data, params, headers);
    }

    static changeRentSale(id, data, params = {}, headers = {}) {
        return this.request(`${id}/change-rent-sale/`, 'PATCH', data, params, headers);
    }
}
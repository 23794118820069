<template>
  <div class="container-fluid h-100 position-relative">
    <div class="position-absolute top-50 start-50 translate-middle">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.loading-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner-container {
  text-align: center;
}
</style>
<template>
  <div class="h-100 d-flex flex-column">
    <div class="container-fluid">
      <div class="d-flex justify-content-between pt-3">
        <slot name="title">
          <span class="fs-3 text-dark fw-bold">
            {{ add ? "New " + title : (archived ? "Archived " : "") + title + " Detail" }}
            <template v-if="pill">
              <span v-for="pill_child in pill" v-if="Array.isArray(pill)" :class="pill_child?.class"
                    class="position-relative start-0 translate-middle-y badge rounded-pill fs-6 me-2">
                {{ pill_child?.text }}</span>
              <span v-else :class="pill?.class"
                    class="position-relative start-0 translate-middle-y badge rounded-pill fs-6">
                {{ pill?.text }}</span>
            </template>
          </span>
        </slot>
        <slot name="navigation">
          <div v-if="isAdmin && isNumeric(pk)" class="d-flex flex-row gap-2">
            <router-link v-if="pk>1" :to="{ name: `${title}Update`,params: { pk: +pk-1 }}" class="btn btn-dark">
              <i class="bi bi-arrow-left"></i> Previous
            </router-link>
            <router-link :to="{ name: `${title}List`}" class="btn btn-dark"><i class="bi bi-arrow-up"></i> Up
            </router-link>
            <router-link :to="{ name: `${title}Update`,params: { pk: +pk+1 }}" class="btn btn-dark">
              <i class="bi bi-arrow-right"></i> Next
            </router-link>
          </div>
          <div v-else class="d-flex align-items-center">
            <router-link :to="{ name: `${title}List`}" class="btn btn-dark"><i class="bi bi-arrow-left"></i> Back
            </router-link>
          </div>
        </slot>
      </div> <!-- Title -->
      <div class="m-2">
        <slot name="subtitle"></slot>
      </div><!-- Subtitle -->
      <slot name="nav-tabs">
        <ul class="nav nav-tabs justify-content-md-start" style="height: 3rem;">
          <li v-for="navTabItem of navTabItems" v-if="!add" class="nav-item">
            <div :class="{active : changeView === navTabItem.id}" class="nav-link"
                 role="button" @click="changeView = navTabItem.id">
              <i :class="navTabItem.icon" class="fs-5"></i><span class="ms-2">{{ navTabItem.name }}</span>
              <template v-if="navTabItem.count">
                <span v-if="typeof navTabItem.count === 'number'" class="ms-2 badge rounded-pill text-bg-dark">
                  {{ navTabItem.count }}
                </span>
                <template v-else-if="Object.entries(navTabItem.count).length">
                  <span v-for="(count, priority) in navTabItem.count" :key="priority"
                        :class="priority" class="ms-2 badge rounded-pill">{{ count }}
                  </span>
                </template>
                <span v-else class="ms-2 badge rounded-pill text-bg-dark">0</span>
              </template>
            </div>
          </li>
        </ul>
      </slot> <!-- NavTabs -->
    </div> <!-- Headers -->
    <div class="h-100 overflow-auto">
      <Spinner v-show="loading"/>
      <div v-show="!loading" class="h-100">
        <div class="h-100 d-flex flex-column gap-2 py-2">
          <slot name="manager">
            <Form v-if="changeView === 'form'" v-model:element="element" v-model:loading="loading" :add="add"
                  :archived="archived" :extra="extra" :noSave="noSave" :pk="pk" :service="service"
                  :submitForm="submitForm" :title="title" @loadElement="loadElement">
              <template v-slot:form-image>
                <slot name="form-image"></slot>
              </template>
              <template v-slot:form-fields>
                <slot name="form-fields"></slot>
              </template>
              <template v-slot:form-l-buttons>
                <slot name="form-l-buttons"></slot>
              </template>
              <template v-slot:form-r-buttons>
                <slot name="form-r-buttons"></slot>
              </template>
            </Form> <!-- Form -->
            <Dashboard v-if="changeView=== 'dashboard' && element.id" ref="dashboardComp"
                       v-model:availability-dates="availabilityDates" v-model:bookings_to_update="bookings_to_update"
                       v-model:dashboardUnits="dashboardUnits" v-model:holidays="holidays" v-model:loading="loading"
                       v-model:unitHistories="unitHistories" :chartOptions="chartOptions" :pk="pk" :service="service"
                       :title="title" @loadElement="loadElement">
              <template v-slot:dashboard>
                <slot name="dashboard"></slot>
              </template>
              <template v-slot:form-image>
                <slot name="form-image"></slot>
              </template>
              <template v-slot:dashboard-fields>
                <slot name="dashboard-fields"></slot>
              </template>
            </Dashboard> <!-- Dashboard -->
          </slot>
        </div>
      </div>
    </div> <!-- Body -->
  </div>
  <slot name="preview">
    <Preview :element="[element]" :reload="loadElement"/>
  </slot>
</template>

<script setup>
import {useRouter} from "vue-router";
import {computed, ref, watch} from "vue";
import {useAppStore, useAuthStore} from "@/stores";
import Form from "@/components/Form.vue";
import Dashboard from "@/components/Dashboard.vue";
import Preview from "@/components/Preview.vue";
import Spinner from "@/components/Spinner.vue";

const emit = defineEmits(['loadElement']);
const props = defineProps({
  navTabItems: {
    type: Array,
    required: false,
    default: () => []
  },
  pill: {
    type: Object,
    required: false,
    default: () => undefined
  },
  extra: {
    type: Object,
    required: false,
    default: () => ({})
  },
  changeView: {
    type: String,
    required: false,
    default: 'changeView'
  },
  submitForm: {
    type: Object,
    required: false,
    default: () => ({})
  },
  pk: {
    type: String,
    required: false,
  },
  add: {
    type: Boolean,
    required: false,
    default: false
  },
  noSave: {
    type: Boolean,
    required: false,
    default: false
  },
  params: {
    type: Object,
    required: false,
    default: {}
  },
  service: {
    type: Function,
    required: false
  },
  title: {
    type: String,
    required: true
  },
  extraPreview: {
    type: Array,
    required: false,
    default: () => []
  },
  chartOptions: {
    type: Object,
    required: false,
    default: () => ({})
  }
});
const router = useRouter();
const appStore = ref(useAppStore());
const authStore = ref(useAuthStore());
const isAdmin = authStore.value.user?.is_superuser ?? false;
const pk = computed(() => props.pk);
const params = computed(() => props.params);
const title = computed(() => props.title);
const submitForm = computed(() => props.submitForm);
const service = computed(() => props.service);
const loading = defineModel('loading', {type: Boolean, required: false, default: false});
const element = defineModel('element', {default: {archived: true}});
const archived = computed(() => element.value.archived);
const dashboardUnits = defineModel('dashboardUnits', {default: []});
const bookings_to_update = defineModel('bookings_to_update', {default: []});
const availabilityDates = defineModel('availabilityDates', {default: undefined});
const unitHistories = defineModel('unitHistories', {default: undefined});
const holidays = defineModel('holidays', {default: []});
const changeView = computed({
  get() {
    return appStore.value[props.changeView]
  },
  set(value) {
    appStore.value[props.changeView] = value
  }
})
const dashboardComp = ref(null);
document.title = `${title.value}`;

const loadDashboard = async () => {
  if (dashboardComp.value) {
    availabilityDates.value = undefined;
    unitHistories.value = undefined;
    bookings_to_update.value = [];
    await dashboardComp.value.loadUnavailability();
    await dashboardComp.value.loadAvailability();
    await dashboardComp.value.loadUnitHistory();
  }
};

const loadElement = async () => {
  try {
    if (pk.value) {
      console.log(`loading ${title.value} detail`)
      loading.value = true;
      const response = await service.value.retrieve(pk.value, params.value);
      element.value = response.data;
      document.title = `${title.value} Detail`;
      loading.value = false;
    } else document.title = `New ${title.value}`;
  } catch (error) {
    loading.value = false;
    if (error === '{"detail":"Not found."}')
      appStore.value.addToast(`Error loading ${title.value}`, `No ${title.value} found for id ${pk.value}`, 'danger');
    else appStore.value.addToast(`Error loading ${title.value}`, error, 'danger');
  } finally {
    await loadDashboard()
    emit('loadElement');
  }
};

defineExpose({loadElement});

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

watch(params, async () => {
  if (service.value) await loadElement();
});
watch(router.currentRoute, (newValue, oldValue) => {
  oldValue.query.archived !== newValue.query.archived && router.go(0);
});
</script>

<style scoped>

</style>
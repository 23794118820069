import {DefaultService} from "@/services/default";

export class AuthService extends DefaultService {
    static resourceURL = ''

    static login(data, params = {}, headers = {}) {
        return this.request('login/', 'POST', data, params, headers, null, 'users');
    }

    static register(data, params = {}, headers = {}) {
        return this.request('register/', 'POST', data, params, headers, null, 'users');
    }

    static verify(data, params = {}, headers = {}) {
        return this.request('verify/', 'POST', data, params, headers, null, 'users');
    }

    static refresh(data, params = {}, headers = {}) {
        return this.request('refresh/', 'POST', data, params, headers, null, 'users');
    }

    static list(params = {}, headers = {}) {
        return this.request('profile/', 'GET', null, params, headers, null, 'users');
    }

}
const OrderStatusList = () => import("@/views/misc/order-status/OrderStatusList.vue");
const OrderStatusChange = () => import("@/views/misc/order-status/OrderStatusChange.vue");

const order_status_routes = [
    {
        path: '',
        name: 'Order StatusList',
        component: OrderStatusList,
        props: route => ({ params: route.query })
    },
    {
        path: 'add',
        name: 'Order StatusCreate',
        component: OrderStatusChange,
        props: route => ({ params: route.query, add: true })
    },
    {
        path: ':pk',
        name: 'Order StatusUpdate',
        component: OrderStatusChange,
        props: route => ({ params: route.query, pk: route.params.pk })
    },
]
export default order_status_routes;
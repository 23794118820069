import {useAppStore} from "@/stores";

export const newToast = (title, message, color = 'secondary') => {
    const today = new Date()
    return {
        id: Math.random(),
        date: today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
        title: title,
        message: message,
        color: color
    }

}
export const copyToClipboard = (event) => {
    let text = event.target.previousSibling.textContent;
    text = text.replace(/^0+/, '');
    navigator.clipboard.writeText(text).then(() => {
        useAppStore().addToast('Success', `"${text}" has been copied to clipboard`, 'success');
    }, () => {
        useAppStore().addToast('Error', "Error while copying to clipboard", 'danger');
    });
}

export const shortDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const options = {month: 'short', day: 'numeric'};
    const f_start_date = startDate.toLocaleDateString('es-ES', options);
    const f_end_date = endDate.toLocaleDateString('es-ES', options);
    return `${f_start_date} - ${f_end_date} `;
}

export const shortDate = (date) => {
    const dateObj = new Date(date);
    const options = {month: 'short', day: 'numeric'};
    return `${dateObj.toLocaleDateString('es-ES', options)}`;
}

export const longDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const options = {month: 'numeric', day: 'numeric', year: 'numeric'};
    return `${startDate.toLocaleString('es-ES', options)} - ${endDate.toLocaleString('es-ES', options)}`;
}

export const longDate = (date) => {
    const dateObj = new Date(date);
    const options = {month: 'numeric', day: 'numeric', year: 'numeric'};
    return `${dateObj.toLocaleString('es-ES', options)}`;
}

export const downloadFile = (response) => {
    const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/\"/g, '');

    // Create a Blob from the PDF Stream
    const file = new Blob([response.data], {
        type: response.headers['content-type'], // Ensure the correct type
    });

    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    // Create a link and trigger the download
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', filename); // Set the filename
    document.body.appendChild(link);
    link.click();

    // Clean up and revoke the URL
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link)
};

export const resImg = (url, suffix) => {
    // Regular expression to match the file name before the extension
    const regex = /(\.[\w\d]+)$/;
    // Replace the match (file extension) with the suffix + file extension
    return url.replace('-scaled', '').replace(regex, `${suffix}$1`);
}
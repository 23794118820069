const UnitHistoryList = () => import("@/views/misc/unit-history/UnitHistoryList.vue");

const unit_history_routes = [
    {
        path: '',
        name: 'Unit HistoryList',
        component: UnitHistoryList,
        props: route => ({ params: route.query })
    }
]
export default unit_history_routes;
<template>
  <ImgTooltip v-if="image && pk" :src="image">
    <template v-slot:title>
      <a v-if="channel_id"
         :href="`${appStore.wcUrl}/wp-admin/post.php?post=${channel_id}&action=edit`"
         class="bi bi-box-arrow-up-right pe-2" target="_blank"/>
      <router-link :class="[archived?'btn-secondary':link_class, is_pill?'badge rounded-pill':'btn-sm']"
                   :to="{name:name,params:{pk: pk}}" class="btn">
        {{ text ?? "" }}
      </router-link>
      <i v-if="!noCopy" class="ms-1 bi-copy" role="button" @click.prevent="copyToClipboard"/>
    </template>
  </ImgTooltip>
  <template v-else-if="pk">
    <a v-if="channel_id"
       :href="`${appStore.wcUrl}/wp-admin/post.php?post=${channel_id}&action=edit`"
       class="bi bi-box-arrow-up-right pe-2" target="_blank"/>
    <router-link :class="[archived?'btn-secondary':link_class, is_pill?'badge':'btn-sm']"
                 :to="{name:name,params:{pk: pk}}" class="btn">
      {{ text ? text : null ?? pk ?? "" }}
    </router-link>
    <i v-if="!noCopy" class="ms-1 bi-copy" role="button" @click.prevent="copyToClipboard"/>
  </template>
  <div v-else-if="text">
    <span :class="[archived?'text-bg-secondary':link_class, is_pill?'badge btn':'']"
          :style="is_pill?'font-size: 0.75em;':'font-size: 0.875rem;'">{{ text ?? "" }}</span>
    <i v-if="!noCopy" class="ms-1 bi-copy" role="button" @click.prevent="copyToClipboard"/>
  </div>
</template>

<!--Usage:-->
<!--<ImgLink :pk="pk" :text="text" :name="name" :image="image" :archived="archived"/>-->

<script setup>
import {copyToClipboard} from "@/assets/extra";
import ImgTooltip from "@/components/ImgTooltip.vue";
import {useAppStore} from "@/stores";

const props = defineProps({
  image: {
    type: String,
    required: false,
  },
  text: {
    required: false,
  },
  pk: {
    type: Number,
    required: false,
  },
  name: {
    type: String,
    required: false,
  },
  archived: {
    type: Boolean,
    required: false,
    default: false,
  },
  noCopy: {
    type: Boolean,
    required: false,
    default: false,
  },
  channel_id: {
    type: String,
    required: false,
  },
  link_class: {
    type: String,
    required: false,
    default: 'btn-outline-dark'
  },
  is_pill: {
    type: Boolean,
    required: false,
    default: false
  }
});
const appStore = useAppStore();
</script>

<style scoped>

</style>
<template>
  <div :id="modalId" :class="extraClass" aria-hidden="true"
       class="modal fade" data-bs-backdrop="false" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <slot name="content">
          <div class="modal-header">
            <slot name="header">
              <h1 class="modal-title fs-5">{{ title }}</h1>
            </slot>
            <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" @click="closeFn"></button>
          </div>
          <div class="modal-body">
            <slot name="body">
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button v-if="!noCancel" class="btn btn-secondary me-auto" data-bs-dismiss="modal" @click="closeFn">
                Cancel
              </button>
              <slot name="buttons"/>
            </slot>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import Modal from "bootstrap/js/dist/modal.js";

const emit = defineEmits(['update:modelValue', 'close']);
const props = defineProps({
  title: {
    type: String,
    required: false
  },
  modelValue: {
    required: true
  },
  noCancel: {
    type: Boolean,
    default: false
  },
  modalId: {
    type: String,
    required: false,
    default: 'alertModal'
  },
  extraClass: {
    type: String,
    required: false
  }
})
const modelValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
onMounted(() => {
  const element = document.getElementById(props.modalId);
  if (element) modelValue.value = new Modal(element);
})

const closeFn = () => {
  emit('close');
}

</script>
<style scoped>

</style>
<template>
  <Table :elements="unitHistories" :pagination="pagination" :table-headers="tableHeaders" noSelections>
    <template v-slot:before-actions>
      <div class="col-auto">
        <button class="btn btn-sm btn-outline-dark search-bar me-auto" @click.prevent="modalElement.show()">Undo
        </button>
      </div> <!-- Undo Button -->
    </template>
    <template v-slot:table-rows>
      <tr v-for="history of unitHistories??[]"
          :class="{'table-danger' : history.line_status==='closed', 'table-active':history.checked}">
        <td>{{ new Date(history.created).toLocaleString() }}</td> <!-- Date -->
        <td>
          <div class="hstack">
            <div v-html="get_last_unit_name(history)"></div>
            <ImgLink :image="history.unit_image" :pk="history.unit" :text="history.unit_sku" name="UnitUpdate"/>
          </div>
        </td> <!-- Unit -->
        <td>
          <ImgLink :pk="history.order_id" :text="history.order_number?.concat(' - ', history.client)"
                   name="OrderUpdate" noCopy/>
        </td> <!-- Order -->
        <td>
          <div v-html="get_status(history)"></div>
        </td> <!-- Status -->
        <td>
          <div v-html="get_location(history)"></div>
        </td> <!-- Location -->
        <td>
          <div v-html="get_booking_status(history)"></div>
        </td> <!-- Booking Status -->
        <td>
          <ImgLink v-if="history.booking" :pk="history.booking" :text="history.booking?.toString()"
                   name="BookingUpdate"/>
          <ImgLink v-if="history.sale" :pk="history.sale" :text="history.sale?.toString()" name="SaleUpdate"/>
        </td>
        <td>
          <DateDisplay :end="history.rent_end" :start="history.rent_start"/>
        </td> <!-- Rent Dates -->
        <td>
          <div>{{ history.username }}</div>
        </td> <!-- User -->
        <td>
          <div>{{ history.note }}</div>
        </td> <!-- Note -->
        <td v-if="isAdmin">
          <ImgLink :text="history.id"/>
        </td> <!-- ID -->
      </tr>
    </template>
  </Table>
  <ModalPopup v-model="modalElement" extra-class="modal-xl" modal-id="undo-modal" title="Undo last Unit History">
    <template v-slot:body>
      <p>Do you want to undo the last unit history entry?</p>
      <table class="table table-bordered table-hover table-sm table-striped text-nowrap">
        <thead class="table-secondary">
        <tr>
          <th>Date</th>
          <th>Unit</th>
          <th>Order</th>
          <th>Unit Status</th>
          <th>Location</th>
          <th>Booking Status</th>
          <th>Order Line</th>
          <th>Dates</th>
          <th>User</th>
          <th>Note</th>
          <th>Id</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="history of unitHistories?.slice(0,1)??[]" :class="{'table-danger' : history.closed}">
          <td>{{ new Date(history.created).toLocaleString() }}</td>
          <td>
            <div class="hstack">
              <div v-html="get_last_unit_name(history)"></div>
              <ImgLink :image="history.unit_image" :pk="history.unit" :text="history.unit_sku" name="UnitUpdate"/>
            </div>
          </td>
          <td>
            <ImgLink :pk="history.order_id" :text="history.order_number?.concat(' - ', history.client)"
                     name="OrderUpdate" noCopy/>
          </td>
          <td>{{ history.status }}</td>
          <td>{{ history.location }}</td>
          <td>{{ history.line_status }}
          </td>
          <td>
            <ImgLink v-if="history.booking" :pk="history.booking" :text="history.booking?.toString()"
                     name="BookingUpdate"/>
            <ImgLink v-if="history.sale" :pk="history.sale" :text="history.sale?.toString()" name="SaleUpdate"/>
          </td>
          <td>
            <DateDisplay :end="history.rent_end" :start="history.rent_start"/>
          </td>
          <td>{{ history.username }}</td>
          <td>{{ history.note }}</td>
          <td v-if="isAdmin">
            <ImgLink :text="history.id"/>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <template v-slot:buttons>
      <button class="btn btn-dark" data-bs-dismiss="modal" @click="undo">Yes</button>
    </template>
  </ModalPopup>
</template>

<script setup>
import Table from "@/components/Table.vue";
import {UnitHistoryService} from "@/services";
import ModalPopup from "@/components/ModalPopup.vue";
import {computed, ref} from "vue";
import {useAppStore, useAuthStore} from "@/stores";
import DateDisplay from "@/components/DateDisplay.vue";
import ImgLink from "@/components/ImgLink.vue";

const emit = defineEmits(['loadElement']);
const props = defineProps({
  params: {
    type: Object,
    required: false,
    default: () => ({})
  },
  pagination: {
    type: Object,
    required: false,
    default: () => ({})
  },
});
const params = computed(() => props.params);
const pagination = computed(() => props.pagination);
const appStore = ref(useAppStore());
const authStore = ref(useAuthStore());
const isAdmin = authStore.value.user?.is_superuser ?? false;
const bookingStatuses = appStore.value.bookingStatuses;
const tableHeaders = [
  {key: null, value: "Date"},
  {key: null, value: "Unit"},
  {key: null, value: "Order"},
  {key: null, value: "Unit Status"},
  {key: null, value: "Location"},
  {key: null, value: "Booking Status"},
  {key: null, value: "Order Line"},
  {key: null, value: "Dates"},
  {key: null, value: "User"},
  {key: null, value: "Note"},
]
if (isAdmin) {
  tableHeaders.push({key: null, value: "Id"});
}
const modalElement = ref(null);
const unitHistories = defineModel('unitHistories', {default: undefined});
const loading = defineModel('loading', {default: undefined});


const loadElement = async () => {
  emit('loadElement');
};

const undo = async () => {
  try {
    console.log('undo last unit history')
    loading.value = true;
    let response = await UnitHistoryService.undo(params.value);
    loading.value = false;
    if (response.status === 200) {
      appStore.value.addToast('Success', "Last change undone", 'success');
      await loadElement();
      document.querySelector('#reload-data').click();
    } else {
      appStore.value.addToast('Error', "Error undoing last change", 'danger');
    }
  } catch (error) {
    loading.value = false;
    console.log(error);
    appStore.value.addToast('Error undoing last change', error, 'danger');
  }
}

const get_status = (history) => {
  if (history.status && history.last_status && history.status !== history.last_status) {
    return `${history.last_status} &rarr; ${history.status}`
  } else {
    return history.status
  }
}

const get_location = (history) => {
  if (history.location && history.last_location && history.location !== history.last_location) {
    return `${history.last_location} &rarr; ${history.location}`
  } else {
    return history.location
  }
}

const get_booking_status = (history) => {
  const status = bookingStatuses.find(e => e.id === history.line_status)?.id ?? history.line_status
  const last_status = bookingStatuses.find(e => e.id === history.last_line_status)?.id ?? history.last_line_status
  if (status && last_status && status !== last_status) {
    return `${last_status} &rarr; ${status}`
  } else {
    return status
  }
}

const get_last_unit_name = (history) => {
  if (history.last_unit !== history.unit) {
    return `${history.last_unit_name} &rarr;&nbsp;`
  } else {
    return ''
  }
}

</script>

<style scoped>

</style>
<template>
  <div class="position-absolute start-50 translate-middle-x" style="top: 48px; z-index: 1020">
    <div class="row g-2">
      <div v-if="(isAdmin) && element" class="col">
        <button aria-controls="preview" class="btn btn-secondary btn-sm w-100" data-bs-target="#preview"
                data-bs-toggle="offcanvas" type="button">
          Preview
        </button>
      </div>
      <div v-if="reload" class="col">
        <button id="reload-data" class="btn btn-secondary btn-sm w-100" @click="reload()">Reload</button>
      </div>
    </div>
  </div>
  <div v-if="(isAdmin) && element" id="preview" class="offcanvas offcanvas-start" data-bs-backdrop="false"
       data-bs-scroll="true" style="width: 15%" tabindex="-1">
    <div class="offcanvas-header">
      <h5 id="offcanvasExampleLabel" class="offcanvas-title">Preview</h5>
      <button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
    </div>
    <div class="offcanvas-body" style="--bs-offcanvas-padding-y: 0; --bs-offcanvas-padding-x: 0;">
      <JsonViewer v-for="item of element" v-if="Array.isArray(element)" :value="item??false" copyable/>
      <JsonViewer v-else :value="element" copyable/>
    </div>
  </div>
</template>
<script setup>
import {useAuthStore} from "@/stores";
import {ref} from "vue";

defineProps({
  element: {required: false},
  reload: {type: Function, required: false},
})
/* get provided env variables */
const authStore = ref(useAuthStore());
const isAdmin = authStore.value.user?.is_superuser ?? false;
</script>
<style>
.jv-container .jv-code {
  padding: 10px;
}
</style>


<template>
  <h5 v-if="subtitle">{{ subtitle }}</h5><!-- Subtitle -->
  <div class="container-fluid d-flex flex-row justify-content-between ps-2 gap-2">
    <div class="col-auto row g-2 align-items-center">
      <slot name="before-actions"></slot><!-- Before Actions -->
      <div v-if="!noSelections" class="col-auto">
        <button class="btn btn-sm btn-outline-dark search-bar" @click.prevent="clearSelection">Clear</button>
      </div> <!-- Clear Button -->
      <div v-if="isAdmin && !noSelections && !noDelete && archived" class="col-auto">
        <button class="btn btn-sm btn-warning search-bar" @click.prevent="actionOpenArchiveModal"> Archive
        </button>
      </div> <!-- Archive Button -->
      <div v-if="isAdmin && !noSelections && !noDelete && restored" class="col-auto">
        <button class="btn btn-sm btn-success search-bar" @click.prevent="actionOpenRestoreModal"> Restore
        </button>
      </div> <!-- Restore Button -->
      <div v-if="isAdmin && !noSelections && !noDelete" class="col-auto">
        <button class="btn btn-sm btn-danger search-bar" @click.prevent="actionOpenDeleteModal"> Delete
        </button>
      </div> <!-- Delete Button -->
      <nav v-if="pagination && pagination?.count" aria-label="Page navigation example" class="col-auto">
        <ul class="pagination pagination-sm m-0">
          <li class="page-item">
            <a :class="{disabled: !pagination.previous}" :href="getNewParams({page:pagination.page-1})"
               aria-label="Previous"
               class="page-link" @click.prevent="updateNewParams({page:pagination.page-1})">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li v-for="page in paginationPages" :key="page"
              :class="{disabled: page==='...', active: pagination.page===page}"
              class="page-item">
            <a :href="getNewParams({page:page})" class="page-link" @click.prevent="updateNewParams({page:page})">
              {{ page }}
            </a>
          </li>
          <li class="page-item">
            <a :class="{disabled: !pagination.next}" :href="getNewParams({page:pagination.page+1})"
               aria-label="Next"
               class="page-link" @click.prevent="updateNewParams({page:pagination.page+1})">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav><!-- Pagination -->
      <div v-if="pagination && pagination.page_size" class="col-auto hstack gap-2">
        <label>Show:</label>
        <select v-model="pagination.page_size" class="form-control form-control-sm" name="action"
                required @change="updateNewParams({page_size:$event.target.value})">
          <option v-for="pageSize in pageSizes" :key="pageSize" :value="pageSize">
            {{ pageSize }} rows
          </option>
          <option v-if="!pageSizes.includes(Number(pagination.page_size))" :value="pagination.page_size">
            {{ pagination.page_size }} rows
          </option>
        </select>
      </div><!-- PageSize -->
      <div class="col-auto">
        <span v-if="!noSelections" class="fs-6">Selected: {{ selectedElements?.length }} / </span>
        <span v-else-if="pagination || elements?.length">Total: </span>
        <span v-if="pagination || elements">{{ pagination?.count || elements?.length }}</span>

      </div> <!-- Selection / Total -->
      <div v-if="!noSelections && selectAllButton" class="col-auto">
        <div class="form-check form-switch">
          <input id="selectAllButton" :value="false" class="form-check-input" type="checkbox"
                 @change="toggleSelectAll">
          <label class="form-check-label">Select All?</label>
        </div>
      </div>
      <slot name="after-actions"></slot><!-- After Actions -->
    </div><!-- Navigation -->
    <div class="col-auto row g-2 align-items-center">
      <div v-if="filters" class="col-auto">
        <ListFilters v-model:filters="filters" :params="params">
          <slot name="filters"></slot>
        </ListFilters>
      </div><!-- Filters -->
      <div v-if="has_export" class="col-auto">
        <button aria-controls="offcanvasFilters" class="btn btn-sm btn-outline-dark" @click="exportData">
          <i class="bi bi-file-earmark-excel-fill"></i> Export
        </button>
      </div><!-- Exports -->
    </div><!-- Filters & Exports -->
  </div><!-- Headers -->
  <div class="overflow-auto mx-2">
    <table v-if="!loading" class="table table-bordered table-hover table-sm table-striped text-nowrap mb-0">
      <thead class="table-secondary">
      <tr>
        <th v-if="!noSelections" class="action-checkbox-column" scope="row">
          <input :name="`action-toggle-${title}`" type="checkbox" @input="toggleSelectPage">
        </th>
        <th v-for="header in tableHeaders" :key="header.key">
          <div :class="getSortIcon(header.key)"
               class="px-0" @click="toggleSorting(header.key)"><span>
              {{ header.value }}
            </span>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <slot name="table-rows"></slot>
      </tbody>
    </table><!-- TABLE -->
  </div>
  <ModalPopup v-model="archiveModal" :title="'Archive '+props.title" modal-id="archive-modal">
    <template v-slot:buttons>
      <button class="btn btn-warning" data-bs-dismiss="modal" @click="actionArchiveSelected">Archive</button>
    </template>
    <template v-slot:body>
      <div v-if="selectAll">
        <p>Are you sure you want to archive all {{ selectedElements?.length }} {{ props.title }}?</p>
      </div>
      <div v-else>
        <p>Are you sure you want to archive this {{ selectedElements?.length }} {{ props.title }}?</p>
        <ul class="list-group">
          <li v-for="element in elements?.filter(e => e.checked)" :key="element.id" class="list-group-item">
            {{ element.__str__ }}
          </li>
        </ul>
      </div>
    </template>
  </ModalPopup>
  <ModalPopup v-model="restoreModal" :title="'Restoe '+props.title" modal-id="restore-modal">
    <template v-slot:buttons>
      <button class="btn btn-warning" data-bs-dismiss="modal" @click="actionRestoreSelected">Restore</button>
    </template>
    <template v-slot:body>
      <div v-if="selectAll">
        <p>Are you sure you want to restore all {{ selectedElements?.length }} {{ props.title }}?</p>
      </div>
      <div v-else>
        <p>Are you sure you want to restore this {{ selectedElements?.length }} {{ props.title }}?</p>
        <ul class="list-group">
          <li v-for="element in elements?.filter(e => e.checked)" :key="element.id" class="list-group-item">
            {{ element.__str__ }}
          </li>
        </ul>
      </div>
    </template>
  </ModalPopup>
  <ModalPopup v-model="deleteModal" :title="'Delete '+props.title" modal-id="delete-modal">
    <template v-slot:buttons>
      <button class="btn btn-danger" data-bs-dismiss="modal" @click="actionDeleteSelected">Delete</button>
    </template>
    <template v-slot:body>
      <div v-if="selectAll">
        <p>Are you sure you want to delete all {{ selectedElements?.length }} {{ props.title }}?</p>
      </div>
      <div v-else>
        <p>Are you sure you want to delete this {{ selectedElements?.length }} {{ props.title }}?</p>
        <ul class="list-group">
          <li v-for="element in elements?.filter(e => e.checked)" :key="element.id" class="list-group-item">
            {{ element.__str__ }}
          </li>
        </ul>
      </div>
    </template>
  </ModalPopup>
</template>

<script setup>
import ListFilters from "@/components/ListFilters.vue";
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import {useAppStore, useAuthStore} from "@/stores";
import ModalPopup from "@/components/ModalPopup.vue";
import {downloadFile} from "@/assets/extra";


const authStore = ref(useAuthStore());
const appStore = ref(useAppStore());
const isAdmin = authStore.value.user?.is_superuser ?? false;
const emit = defineEmits(['loadElements', 'clearSelection'])
const props = defineProps({
  params: {
    type: Object,
    required: false,
    default: {}
  },
  has_export: {
    type: Boolean,
    required: false,
    default: false,
  },
  pagination: {
    type: Object,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  subtitle: {
    type: String,
    required: false,
  },
  tableHeaders: {
    type: Array,
    required: true,
  },
  overrideActions: {
    type: Boolean,
    required: false,
    default: false,
  },
  tableTotal: {
    type: Number,
    required: false,
  },
  noSelections: {
    type: Boolean,
    required: false,
    default: false,
  },
  noDelete: {
    type: Boolean,
    required: false,
    default: false,
  },
  service: {
    type: Function,
  },
})
const router = useRouter();
const loading = defineModel('loading');
const elements = defineModel('elements');
const filters = defineModel('filters');
const params = computed(() => props.params);
const title = computed(() => props.title);
const service = computed(() => props.service);
const archived = computed(() => ['none', 'false', undefined].includes(props.params?.archived))
const restored = computed(() => ['none', 'true'].includes(props.params?.archived))
const pagination = computed(() => props.pagination);
const selectAllButton = ref(false);
const selectAll = defineModel('selectAll');
const selectedElements = computed(() => {
  if ((selectAll.value ?? []).length > 0) {
    return selectAll.value
  } else {
    return elements.value?.filter(e => e?.checked).map(e => e.id)
  }
});
const MAX_PAGES = 7;   // Maximum number of pages to display
const pageSizes = [24, 48, 64, 128]
const paginationPages = computed(() => {
  const pages = [];
  if (!pagination.value) return pages;
  const {count, page_size, page} = pagination.value;
  const pageCount = Math.ceil(count / page_size);

  if (pageCount <= MAX_PAGES) {
    return Array.from({length: pageCount}, (_, index) => index + 1);
  }

  if (page <= 3) {
    // Display pages 1 to 4
    pages.push(...Array.from({length: 4}, (_, index) => index + 1));
    pages.push('...'); // Ellipsis
    pages.push(pageCount); // Last page
  } else if (page > pageCount - 3) {
    pages.push(1); // First page
    pages.push('...'); // Ellipsis
    // Display last 4 pages
    pages.push(...Array.from({length: 4}, (_, index) => pageCount - 3 + index));
  } else {
    pages.push(1); // First page
    pages.push('...'); // Ellipsis
    // Display current page and pages before and after
    pages.push(page - 1, page, page + 1);
    pages.push('...'); // Ellipsis
    pages.push(pageCount); // Last page
  }

  return pages;
});
const multiple_pages = computed(() => pagination.value?.count > pagination.value?.page_size);
const archiveModal = ref(null);
const restoreModal = ref(null);
const deleteModal = ref(null);

const loadElements = () => {
  emit('loadElements');
}


const actionOpenArchiveModal = async () => {
  if (selectedElements.value.length < 1) {
    appStore.value.addToast('Warning', 'Please select at least one element from the table', 'danger');
    return;
  }
  archiveModal.value.show();
}


const actionOpenRestoreModal = async () => {
  if (selectedElements.value.length < 1) {
    appStore.value.addToast('Warning', 'Please select at least one element from the table', 'danger');
    return;
  }
  restoreModal.value.show();
}


const actionOpenDeleteModal = async () => {
  if (selectedElements.value.length < 1) {
    appStore.value.addToast('Warning', 'Please select at least one element from the table', 'danger');
    return;
  }
  deleteModal.value.show();
}


const toggleSelectPage = (event) => {
  elements.value?.forEach(e => e.checked = event.target.checked);
  selectAllButton.value = event.target.checked && multiple_pages.value && selectAll.value !== undefined;
}

const toggleSelectAll = (event) => {
  if (event.target.checked) {
    getAllElements(); // Temporary override
  } else {
    selectAll.value = [] // Revert back to computed logic
    elements.value.forEach(e => e.checked = true)
  }
}

const clearSelection = () => {
  elements.value?.forEach(e => e.checked = false);
  selectAll.value = [];
  document.querySelector('#selectAllButton') ? document.querySelector('#selectAllButton').checked = false : null;
  document.querySelectorAll('input[name^="action-toggle"]').forEach((el) => el.checked = false);
  emit('clearSelection');
}

const getAllElements = async () => {
  try {
    let response = await service.value.list({"all": true, ...params.value});
    if (response.status === 200) {
      selectAll.value = response.data.map(e => e.id);
    } else {
      appStore.value.addToast('Error', `Error retrieving all ${title.value}`, 'danger');
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast(`Error retrieving all ${title.value}`, error, 'danger');
  }
}

const actionArchiveSelected = async () => {
  try {
    archiveModal.value.hide();
    loading.value = true;
    let response = await service.value.batchArchive({ids: selectedElements.value});
    if (response.status === 200) {
      appStore.value.addToast('Success', `${title.value} archived`, 'success');
    } else {
      appStore.value.addToast('Error', `Error archiving ${title.value}`, 'danger');
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast(`Error archiving ${title.value}`, error, 'danger');
  } finally {
    loading.value = false;
    clearSelection();
    loadElements()
  }
}

const actionRestoreSelected = async () => {
  try {
    restoreModal.value.hide();
    loading.value = true;
    let response = await service.value.batchRestore({ids: selectedElements.value});
    if (response.status === 200) {
      appStore.value.addToast('Success', `${title.value} restored`, 'success');
    } else {
      appStore.value.addToast('Error', `Error restoring ${title.value}`, 'danger');
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast(`Error restoring ${title.value}`, error, 'danger');
  } finally {
    loading.value = false;
    clearSelection();
    loadElements()
  }
}

const actionDeleteSelected = async () => {
  try {
    deleteModal.value.hide();
    loading.value = true;
    let response = await service.value.batchDelete({ids: selectedElements.value});
    if (response.status === 200) {
      appStore.value.addToast('Success', `${title.value} deleted`, 'success');
    } else {
      appStore.value.addToast('Error', `Error deleting ${title.value}`, 'danger');
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast(`Error deleting ${title.value}`, error, 'danger');
  } finally {
    loading.value = false;
    clearSelection();
    loadElements()
  }
}

const exportData = async () => {
  try {
    loading.value = true;
    const response = await service.value.export(params.value);
    await downloadFile(response);
    appStore.value.addToast('Success', "Orders file exported", 'success');
  } catch (error) {
    console.log(error);
    appStore.value.addToast('DownloadError', error, 'danger');
  } finally {
    loading.value = false;
  }
}

const getNewParams = (newParams) => {
  // Get current route path and query
  const {path, query} = router.currentRoute.value;

  // Merge current query params with newParams
  let mergeParams = {...query, ...newParams};

  // Use URLSearchParams to construct query string
  const encodedParams = new URLSearchParams();
  for (const [key, value] of Object.entries(mergeParams)) {
    if (Array.isArray(value)) {
      value.forEach(item => encodedParams.append(key, item));
    } else {
      encodedParams.set(key, value);
    }
  }
  // Return the current path combined with the new serialized query string
  return `${path}?${encodedParams.toString()}`;
};

const updateNewParams = (newParams) => {
  const {query} = router.currentRoute.value;
  // Merge current query params with newParams
  let mergeParams = {...query, ...newParams};
  router.push({path: router.currentRoute.value.path, query: mergeParams});
};

const toggleSorting = async (key) => {
  if (!key) {
    return
  }
  const ordering = params.value.ordering || "";

  let orderList = ordering ? ordering.split(',') : [];

  if (orderList.includes(`-${key}`)) {
    const index = orderList.indexOf(`-${key}`);
    if (index > -1) orderList.splice(index, 1);
    orderList.push(key);
  } else if (orderList.includes(key)) {
    const index = orderList.indexOf(key);
    if (index > -1) orderList.splice(index, 1);
  } else {
    orderList.push(`-${key}`);
  }
  if (orderList.length > 0) {
    const newSort = {ordering: orderList.join(',')};
    console.log(newSort);
    updateNewParams(newSort)
  } else {
    const filteredParams = Object.keys(params.value).reduce((object, key) => {
      if (key === 'page_size') {
        object[key] = params.value[key];
      } else if (filters.value[key]) {
        object[key] = params.value[key];
      }
      return object
    }, {})
    await router.push({query: filteredParams})
  }
}

const getSortIcon = (key) => {
  if (!key) {
    return
  }
  const ordering = params.value.ordering || "";
  let orderList = ordering.split(',');
  if (orderList.includes(`-${key}`)) {
    return 'sorting sort-desc d-flex justify-content-between'
  } else if (orderList.includes(key)) {
    return 'sorting sort-asc d-flex justify-content-between'
  } else {
    return 'sorting'
  }
}

</script>

<style scoped>
th > div.sorting {
  cursor: pointer;
}

th > div.sorting:hover {
  opacity: 0.7;
  text-decoration: double;
}

th > div.sorting.sort-desc:after,
th > div.sorting.sort-asc:after {
  font-family: "bootstrap-icons", emoji !important;
  padding-left: 0.5rem;
}

th > div.sorting.sort-desc:after {
  content: "\F229";

}

th > div.sorting.sort-asc:after {
  content: "\F235";
}
</style>
<template>
  <slot name="dashboard">
    <div class="h-100 d-flex px-2 gap-2">
      <div class="h-100 d-flex flex-column overflow-auto w-100 pe-2 gap-2">
        <div class="row g-2">
          <slot name="form-image"></slot>
          <div class="col">
            <div class="row g-3">
              <slot name="dashboard-fields"/>
              <div class="col-12">
                <DashboardUnits v-if="dashboardUnits.length" v-model:elements="dashboardUnits"
                                v-model:loading="loading" @loadElement="loadElement"/>
              </div> <!-- Unit Table -->
            </div>
          </div> <!-- Fields -->
        </div>
        <div class="col-12">
          <h4>Bookings
            <span v-if="!availabilityDates" aria-hidden="true" class="spinner-border spinner-border-sm ms-auto fs-6"/>
          </h4>
          <hr class="mt-0">
          <div v-if="bookings_to_update.length" class="row justify-content-center">
            <form class="col-auto" @submit.prevent="reassign_bookings">
              <div class="row justify-content-between align-items-center">
                <label class="col-auto">Bookings to Update</label>
                <input class="col-auto btn btn-sm btn-primary" type="submit" value="Update">
              </div>
              <div class="row pt-1">
                <div class="col border border-2 rounded">
                  <div v-for="booking in bookings_to_update" class="row">
                    <div class="col-auto">
                      {{ booking.order }}-{{ booking.client }}-{{ booking.to.variation.sku }} :
                    </div>
                    <div class="col-auto text-nowrap">
                      "{{ booking.from }}"
                    </div>
                    <div class="col-auto"> &rArr;</div>
                    <div class="col-auto text-nowrap">
                      "{{ booking.to.name }}"
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="row justify-content-center g-0">
            <div class="col-12 col-lg">
              <highcharts v-if="availabilityDates" :constructorType="'ganttChart'" :options="chartOptions" class="hc"/>
            </div>
            <div class="col-auto">
              <CalendarWidget :dates="range" :disabledDates="disabledDates"/>
            </div> <!-- Calendar -->
          </div>
        </div> <!-- Gantt Chart -->
        <div class="col-12">
          <h4>History
            <span v-if="!unitHistories" aria-hidden="true" class="spinner-border spinner-border-sm ms-auto fs-6"/>
          </h4>
          <hr class="mt-0">
          <div class="d-flex flex-column gap-2">
            <UnitHistoryTable v-if="unitHistories" v-model:loading="loading" v-model:unitHistories="unitHistories"
                              :pagination="pagination" :params="{[lowerTitle]:pk} " @loadElement="loadElement"/>
          </div>
        </div> <!-- History Table -->
      </div>
    </div>
  </slot>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {useAppStore} from "@/stores";
import CalendarWidget from "@/components/CalendarWidget.vue";
import UnitHistoryTable from "@/components/UnitHistoryTable.vue";
import {BookingService, HolidayService, UnitHistoryService} from "@/services";
import DashboardUnits from "@/components/DashboardUnits.vue";

const emit = defineEmits(['loadElement']);
const props = defineProps({
  pk: {
    type: String,
  },
  params: {
    type: Object,
    required: false,
    default: () => ({})
  },
  service: {
    type: Function,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  disabledDates: {
    type: Array,
    required: false,
    default: () => []
  },
  chartOptions: {
    type: Object,
    required: true
  },
});
const appStore = ref(useAppStore());
const pk = computed(() => props.pk);
const title = computed(() => props.title);
const lowerTitle = computed(() => title.value.toLowerCase());
const service = computed(() => props.service);
const range = ref([]);
const disabledDates = ref([]);
const dashboardUnits = defineModel('dashboardUnits', {default: []});
const loading = defineModel('loading', {type: Boolean, required: false, default: false});
const bookings_to_update = defineModel('bookings_to_update', {default: []});
const availabilityDates = defineModel('availabilityDates', {default: undefined});
const unitHistories = defineModel('unitHistories', {default: undefined});
const holidays = defineModel('holidays', {default: undefined});
const pagination = ref(null);

onMounted(() => {
  availabilityDates.value = undefined;
  unitHistories.value = undefined;
  loadHolidays();
  loadUnavailability();
  loadAvailability();
  loadUnitHistory();
});

const loadUnavailability = async () => {
  console.log('loading unavailability')
  try {
    const response = await service.value.unavailability(pk.value);
    disabledDates.value = response.data;
  } catch (error) {
    console.log(error);
    appStore.value.addToast('Error', error, 'danger');
  }
};

const loadAvailability = async () => {
  console.log('loading availability')
  try {
    const response = await service.value.availability(pk.value);
    availabilityDates.value = response.data;
  } catch (error) {
    console.log(error);
    appStore.value.addToast('Error loading availability', error, 'danger');
  }
};

const loadHolidays = async () => {
  console.log('loading holidays')
  try {
    const response = await HolidayService.list({country: 'ES'});
    holidays.value = response.data;
  } catch (error) {
    console.log(error);
    appStore.value.addToast('Error loading holidays', error, 'danger');
  }
};

const loadUnitHistory = async () => {
  console.log('loading status history')
  try {
    const response = await UnitHistoryService.list({[title.value.toLowerCase()]: pk.value});
    unitHistories.value = response.data;
    if (response.headers['pagination']) pagination.value = JSON.parse(
        (response.headers['pagination'] ?? '').replace(/'/g, '"')?.replace(/None/g, 'null'));
  } catch (error) {
    console.log(error);
    appStore.value.addToast('Error', error, 'danger');
  }
};

const loadElement = async () => {
  emit('loadElement');
};

const reassign_bookings = async () => {
  //sort by booking blocking start descending
  const data = bookings_to_update.value.sort((a, b) => b.date - a.date).map(b => ({
    pk: b.id,
    unit: b.to.id,
  }));
  try {
    appStore.value.addToast('Success', "Bookings are updating on background... please dont make any changes", 'success');
    const response = await BookingService.batchAssign(data);
    if (response.status === 200) {
      appStore.value.addToast('Success', "Bookings updated successfully", 'success');
      await loadElement();
      bookings_to_update.value = [];
    } else {
      appStore.value.addToast('Error', response.data, 'danger');
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast('Error', error, 'danger');
  }
};

defineExpose({loadUnavailability, loadAvailability, loadUnitHistory});
</script>

<style scoped>

</style>
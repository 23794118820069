<template>
  <div class="h-100 d-flex px-2 gap-2 flex-column justify-content-between">
    <div class="h-100 d-flex pe-2 gap-2 justify-content-between overflow-auto">
      <slot name="form-image"></slot>
      <div>
        <div class="row g-3">
          <slot name="form-fields"/>
        </div>
      </div>
    </div>
    <div class="col-12 p-2 d-flex gap-2">
      <div class="me-auto d-flex gap-2">
        <template v-if="isAdmin && !add">
          <div v-if="archived" class="btn btn-success" role="button" @click.prevent="restoreElement"> Restore</div>
          <div v-else class="btn btn-warning" role="button" @click.prevent="archiveElement"> Archive</div>
          <div class="btn btn-danger" role="button" @click.prevent="deleteElement"> Delete</div>
        </template>
        <slot name="form-l-buttons"/>
      </div> <!-- Left Buttons -->
      <div class="ms-auto d-flex gap-2">
        <slot name="form-r-buttons"/>
        <div v-if="!noSave" class="btn btn-dark" type="submit" @click.prevent="saveElement">Save</div>
      </div> <!-- Right Buttons -->
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import {useAppStore, useAuthStore} from "@/stores";
import {useRouter} from "vue-router";

const emit = defineEmits(['loadElement']);
const props = defineProps({
  extra: {type: Object, required: false, default: () => ({})},
  submitForm: {type: Object, required: true},
  pk: {type: String,},
  add: {type: Boolean, required: false, default: false},
  noSave: {type: Boolean, required: false, default: false},
  params: {type: Object, required: false, default: () => ({})},
  service: {type: Function, required: true},
  title: {type: String, required: true},
  archived: {type: Boolean, required: false, default: false},
});
const appStore = ref(useAppStore());
const authStore = ref(useAuthStore());
const isAdmin = authStore.value.user?.is_superuser ?? false;
const router = useRouter();
const pk = computed(() => props.pk);
const params = computed(() => props.params);
const title = computed(() => props.title);
const submitForm = computed(() => props.submitForm);
const service = computed(() => props.service);
const loading = defineModel('loading', {type: Boolean, required: false, default: false});

const saveElement = async () => {
  console.log('submit Save');
  try {
    loading.value = true;
    if (pk.value) { // update
      console.log(submitForm.value)
      let response = await service.value.update(pk.value, submitForm.value, params.value, {'Content-Type': 'application/json'});
      if (response.status === 200) {
        appStore.value.addToast('Success', `${title.value} updated successfully`, 'success');
      } else {
        appStore.value.addToast('Error', `Error while updating ${title.value}`, 'danger');
      }
    } else { // create
      let response = await service.value.create(submitForm.value, params.value, {'Content-Type': 'application/json'});
      if (response.status === 201) {
        appStore.value.addToast('Success', `${title.value} created successfully`, 'success');
        await router.push({name: `${title.value}Update`, params: {pk: response.data.id}, query: params.value});
      } else {
        appStore.value.addToast('Error', `Error while creating ${title.value}`, 'danger');
      }
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast(`Error Creating ${title.value}`, error, 'danger');
  } finally {
    loading.value = false;
    emit('loadElement');
  }
};

const deleteElement = async () => {
  try {
    loading.value = true;
    if (!confirm(`Are you sure you want to delete this ${title.value}?`)) return;
    let response = await service.value.delete(pk.value, params.value)
    if (response.status === 204) {
      appStore.value.addToast('Success', `${title.value} deleted successfully`, 'success');
      await router.push({name: `${title.value}List`, query: params.value});
    } else {
      appStore.value.addToast('Error', `Error while deleting ${title.value}`, 'danger');
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast('Error', error, 'danger');
  } finally {
    loading.value = false;
  }
}

const archiveElement = async () => {
  try {
    loading.value = true;
    if (!confirm(`Are you sure you want to archive this ${title.value}?`)) return;
    let response = await service.value.archive(pk.value, params.value)
    if (response.status === 204) {
      appStore.value.addToast('Success', `${title.value} archived successfully`, 'success');
    } else {
      appStore.value.addToast('Error', `Error while archiving ${title.value}`, 'danger');
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast('Error', error, 'danger');
  } finally {
    loading.value = false;
    emit('loadElement');
  }
}

const restoreElement = async () => {
  try {
    loading.value = true;
    if (!confirm(`Are you sure you want to restore this ${title.value}?`)) return;
    let response = await service.value.restore(pk.value, params.value)
    if (response.status === 204) {
      appStore.value.addToast('Success', `${title.value} restored successfully`, 'success');
    } else {
      appStore.value.addToast('Error', `Error while restoring ${title.value}`, 'danger');
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast('Error', error, 'danger');
  } finally {
    loading.value = false;
    emit('loadElement');
  }
}

</script>

<style scoped>


</style>
<template>
  <div class="d-flex flex-column gap-2">
    <Table :elements="elements" :table-headers="tableHeaders" no-delete @clearSelection="clearSelection">
      <template v-slot:before-actions>
        <div class="col-auto hstack gap-2">
          <label class="">Status: </label>
          <select v-model="selectedStatus" class="form-select form-select-sm">
            <option value="">---------</option>
            <option v-for="status in statuses" :key="status.id" :value="status.name">
              {{ status.name }}
            </option>
          </select>
        </div><!-- Change Status -->
        <div class="col-auto hstack gap-2">
          <label>Location:</label>
          <select v-model="selectedLocation" class="form-select form-select-sm">
            <option value="">---------</option>
            <option v-for="location in locations" :key="location.id" :value="location.name">
              {{ location.name }}
            </option>
          </select>
        </div><!-- Change Location -->
        <div class="col-auto hstack gap-2">
          <label>Storage:</label>
          <select v-model="selectedStorage" class="form-select form-select-sm">
            <option value="">---------</option>
            <option v-if="!storages.length" :value="selectedStorage">{{ selectedStorage }}</option>
            <option v-for="storage in storages" v-else :key="storage.id" :value="storage.name">
              {{ storage.name }}
            </option>
          </select>
        </div><!-- Change Storage -->
        <div class="col-auto">
          <button class="btn btn-sm btn-outline-dark search-bar" @click.prevent="openChangeModal()">Change</button>
        </div><!-- Change Button -->
        <div class="col-auto">
          <button class="btn btn-sm btn-outline-dark search-bar" title="Generate QR Codes"
                  @click.prevent="generateQRCodes()"><i class="bi bi-qr-code"></i>
          </button>
        </div><!-- QR Button -->
        <div class="col-auto">
          <button class="btn btn-sm btn-outline-dark search-bar" title="Generate Labels"
                  @click.prevent="generateLabels"><i class="bi bi-tag-fill"></i>
          </button>
        </div><!-- Label Button -->
      </template>
      <template v-slot:table-rows>
        <tr v-for="unit of elements??[]" :class="{'table-primary':unit.checked}">
          <th class="action-checkbox-column" scope="row">
            <input :id="unit.id" v-model="unit.checked" name="action-select" type="checkbox">
          </th> <!-- Action checkbox column -->
          <td>
            <ImgLink :archived="unit.archived" :image="unit.variation.image?.file_url" :pk="unit.id" :text="unit.sku"
                     name="UnitUpdate"/>
          </td><!-- SKU -->
          <td>
            <select :class="unit.archived?'btn-secondary':'btn-outline-dark'" class="btn btn-sm dropdown-toggle"
                    @change="getSelectedElements([unit], $event.target, null, null)">
              <option v-for="status in statuses" v-if="statuses.length" :key="status.id"
                      :selected="unit.status?.id===status.id" :value="status.name">
                {{ status.name }}
              </option>
              <option v-else :value="unit.status?.name" selected>{{ unit.status?.name }}</option>
            </select>
          </td><!-- Unit Status -->
          <td>
            <select :class="unit.archived?'btn-secondary':'btn-outline-dark'" class="btn btn-sm dropdown-toggle"
                    @change="getSelectedElements([unit], null, $event.target, null)">
              <option v-if="!locations.length" :value="unit.location.name" selected>{{ unit.location.name }}</option>
              <option v-for="location in locations" v-else :key="location.id" :selected="unit.location.id===location.id"
                      :value="location.name">
                {{ location.name }}
              </option>
            </select>
          </td><!-- Location -->
          <td>
            <select :class="unit.archived?'btn-secondary':'btn-outline-dark'" class="btn btn-sm dropdown-toggle"
                    @change="getSelectedElements([unit], null, null, $event.target)">
              <option v-if="!storages.length" :value="unit.storage.name" selected>{{ unit.storage.name }}</option>
              <option v-for="storage in storages" v-else :key="storage.id" :selected="unit.storage.id===storage.id"
                      :value="storage.name">
                {{ storage.name }}
              </option>
            </select>
          </td><!-- Storage -->
          <td>
            <div>
              <span :class="getType(unit)?.color" class="badge rounded-pill">{{ getType(unit)?.text }}</span>
            </div>
          </td><!-- Type -->
          <td>
            <div v-if="unit.current_booking" v-tooltip="displayBookingDateTooltip(unit.current_booking)">
              <ImgLink :is_pill="true" :pk="unit.current_booking?.id"
                       :text="shortDateRange(unit.current_booking?.rent_start, unit.current_booking?.rent_end)"
                       link_class="text-bg-success" name="BookingUpdate" no-copy/>
            </div>
            <div v-if="unit.current_sale">
              <ImgLink :is_pill="true" :pk="unit.current_sale?.id"
                       :text="shortDate(unit.current_sale?.fulfillment_date)"
                       link_class="text-bg-warning" name="SaleUpdate" no-copy/>
            </div>
          </td><!-- Order Line -->
          <td>
            <div v-if="unit.current_booking" class="col-12">
              <ImgLink :channel_id="unit.current_booking?.order_channel_id"
                       :pk="unit.current_booking?.order" :text="unit.current_booking?.order_number" name="OrderUpdate"/>
            </div>
            <div v-if="unit.current_sale" class="col-12">
              <ImgLink :channel_id="unit.current_sale?.order_channel_id"
                       :pk="unit.current_sale?.order" :text="unit.current_sale?.order_number" name="OrderUpdate"/>
            </div>
          </td><!-- Order -->
          <td>
            {{ unit.current_booking?.client ?? unit.current_sale?.client }}
          </td><!-- Client -->
          <td>
            <div v-if="unit.next_booking" v-tooltip="displayBookingTooltip(unit.next_booking)">
              <ImgLink :pk="unit.next_booking?.id" :text="shortDateRange(unit.next_booking?.rent_start, unit.next_booking?.rent_end)" name="BookingUpdate"
                       no-copy/>
            </div>
          </td><!-- Next Booking -->
        </tr>
      </template>
    </Table> <!-- Table -->
  </div>
  <ModalPopup v-model="changeModalElement" :title="changeModalData?.title" extra-class="modal-xl"
              modal-id="change-modal">
    <template v-if="changeModalData" v-slot:body>
      <div v-if="changeModalData.data.length">
        <div class="d-flex justify-content-between">
          <p class="fs-5" v-html="changeModalData.subtitle"/>
        </div>
        <table class="table table-bordered table-hover table-sm text-nowrap">
          <thead class="table-secondary">
          <tr>
            <th scope="col">Check</th>
            <th>SKU</th>
            <th>Name</th>
            <th>Status</th>
            <th>Location</th>
            <th>Storage</th>
            <th>Order Line</th>
            <th>Order</th>
            <th>Client</th>
            <th>Order Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="element of changeModalData.data"
              :class="{'table-active':element.checked,'table-danger':element.closed}">
            <th class="action-checkbox-column">
              <input :id="element.booking" v-model="element.checked" name="action-select" type="checkbox">
            </th> <!-- Action checkbox column -->
            <td>{{ element.sku }}</td>
            <td>{{ element.name }}</td>
            <td>{{ element.last_status }}</td>
            <td>{{ element.last_location }}</td>
            <td>{{ element.last_storage }}</td>
            <td>
              <div v-if="element.booking" v-tooltip="longDateRange(element.rent_start, element.rent_end)">
                <ImgLink :is_pill="true" :text="shortDateRange(element.rent_start, element.rent_end)"
                         link_class="text-bg-success" no-copy/>
              </div>
              <div v-else-if="element.sale" v-tooltip="new Date(element.rent_start).toLocaleString()">
                <ImgLink :is_pill="true" :text="element.rent_start" link_class="text-bg-warning" no-copy/>
              </div>
            </td>
            <td>{{ element.order }}</td>
            <td>{{ element.client }}</td>
            <td>{{ element.last_order_status }}</td>
          </tr>
          </tbody>
        </table>
      </div><!-- Normal Data table -->
      <div v-if="changeModalData.closedElements.data.length">
        <div class="d-flex justify-content-between">
          <p class="fs-5" v-html="changeModalData.closedElements.subtitle"/>
        </div>
        <table class="table table-bordered table-hover table-sm text-nowrap">
          <thead class="table-secondary">
          <tr class="table-danger">
            <th>SKU</th>
            <th>Name</th>
            <th>Status</th>
            <th>Location</th>
            <th>Storage</th>
            <th>Order Line</th>
            <th>Order</th>
            <th>Client</th>
            <th>Order Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="element of changeModalData.closedElements.data"
              :class="{'table-active table-danger':element.checked}">
            <td>{{ element.sku }}</td>
            <td>{{ element.name }}</td>
            <td>{{ element.last_status }}</td>
            <td>{{ element.last_location }}</td>
            <td>{{ element.last_storage }}</td>
            <td>
              <DateDisplay :end="element.rent_end" :start="element.rent_start"/>
            </td>
            <td>{{ element.order }}</td>
            <td>{{ element.client }}</td>
            <td>{{ element.last_order_status }}</td>
          </tr>
          </tbody>
        </table>
      </div><!-- Closed Data table -->
      <div class="form-floating">
        <textarea v-model="changeModalData.note" class="form-control" style="height: 51px"/>
        <label class="form-label">Note</label>
      </div><!-- Note -->
    </template>
    <template v-slot:buttons>
      <button v-if="showModalYesButton" class="btn btn-dark" data-bs-dismiss="modal" @click="changeUnitField">Yes
      </button>
    </template>
  </ModalPopup>
</template>

<script setup>
import ModalPopup from "@/components/ModalPopup.vue";
import DateDisplay from "@/components/DateDisplay.vue";
import Table from "@/components/Table.vue";
import {computed, onMounted, ref, watch} from "vue";
import {LocationService, UnitService, UnitStatusService} from "@/services";
import ImgLink from "@/components/ImgLink.vue";
import {useAppStore} from "@/stores";
import {longDateRange, shortDate, shortDateRange} from "@/assets/extra";

const emit = defineEmits(['loadElement']);
const appStore = ref(useAppStore());
const elements = defineModel('elements');
const loading = defineModel('loading');
const statuses = ref([]);
const locations = ref([]);
const storages = computed(() => {
  if (locations.value.length > 0) {
    return locations.value.filter(location => location?.type === 'warehouse')
  }
  return [];
});
const changeModalElement = ref();
const changeModalData = ref({
  closedElements: {data: [], subtitle: ''},
  data: [],
});
const showModalYesButton = computed(() => Boolean(changeModalData.value?.data.find(el => el.checked)));
const selectedStatus = ref("");
const selectedLocation = ref("");
const selectedStorage = ref("");
const selectedElements = computed(() => elements.value?.filter(e => e?.checked));
const tableHeaders = [
  {key: null, value: "SKU"},
  {key: null, value: "Status"},
  {key: null, value: "Location"},
  {key: null, value: "Storage"},
  {key: null, value: "Type"},
  {key: null, value: "Order Line"},
  {key: null, value: "Order"},
  {key: null, value: "Client"},
  {key: null, value: "Next Booking"},
];

onMounted(() => {
  loadStatuses();
  loadLocations();
  changeModalElement.value._element.addEventListener('hidden.bs.modal', () => {
    clearModalData();
  });
});

const loadElement = () => {
  emit('loadElement');
};

const loadStatuses = async () => {
  if (statuses.value.length === 0) {
    console.log('loading statuses');
    try {
      const response = await UnitStatusService.list();
      statuses.value = response.data.map((el) => {
        return {
          id: el.id,
          name: el.name,
          to_location: el.to_location
        }
      });
    } catch (error) {
      console.log(error);
      appStore.value.addToast('Error', error, 'danger');
    }
  }
};

const loadLocations = async () => {
  if (locations.value.length === 0) {
    console.log('loading locations')
    try {
      const response = await LocationService.list();
      locations.value = response.data.map((el) => {
        return {
          id: el.id,
          name: el.name,
          type: el.type
        }
      });
    } catch (error) {
      console.log(error);
      appStore.value.addToast('Error', error, 'danger');
    }
  }
};

const getSelectedElements = (elements, newStatus, newLocation, newStorage) => {
  const status = statuses.value.find(el => el.name === newStatus?.value) ?? null
  const location = locations.value.find(el => el.name === newLocation?.value) ?? null
  const storage = storages.value.find(el => el.name === newStorage?.value) ?? null
  if (newStatus) newStatus.selectedIndex = statuses.value.findIndex(el => el.name === elements[0].status.name)
  if (newLocation) newLocation.selectedIndex = locations.value.findIndex(el => el.name === elements[0].location.name)
  if (newStorage) newStorage.selectedIndex = storages.value.findIndex(el => el.name === elements[0].storage.name)
  openChangeModal(elements, status, location, storage)
}

const openChangeModal = async (
    changeElements,
    newStatus = statuses.value.find(el => el.name === selectedStatus.value),
    newLocation = locations.value.find(el => el.name === selectedLocation.value),
    newStorage = storages.value.find(el => el.name === selectedStorage.value)  // New parameter
) => {

  if (!changeElements) {
    if (!selectedElements.value.length) {
      appStore.value.addToast('Warning', 'Please select at least one element from the table', 'danger');
      return;
    }
    changeElements = selectedElements.value;
  }

  const changedStatuses = [];
  const changedLocations = [];
  const changedStorages = [];  // New array for storages
  changeElements = changeElements.filter(el => {
    const status = el.status?.id !== newStatus?.id;
    const location = el.location?.id !== newLocation?.id;
    const storage = el.storage?.id !== newStorage?.id;  // New storage check
    if (newLocation) changedLocations.push(location ? newLocation : undefined);
    if (newStatus) changedStatuses.push(status ? newStatus : undefined);
    if (newStorage) changedStorages.push(storage ? newStorage : undefined);  // New storage push
    return (newLocation && location) || (newStatus && status) || (newStorage && storage);
  });

  if (!changeElements.length) {
    appStore.value.addToast('Warning', 'No units to update', 'danger');
    return;
  }

  newStatus = changedStatuses.some(e => e !== undefined) ? newStatus : undefined;
  newLocation = changedLocations.some(e => e !== undefined) ? newLocation : undefined;
  newStorage = changedStorages.some(e => e !== undefined) ? newStorage : undefined;  // New storage check

  changeModalData.value.title = 'Multiple Changes';

  changeModalData.value.subtitle = newStatus && newLocation && newStorage
      ? `To Status <strong>${newStatus.name}</strong>, Location <strong>${newLocation.name}</strong>, and Storage <strong>${newStorage.name}</strong>`
      : newStatus && newLocation
          ? `To Status <strong>${newStatus.name}</strong> and Location <strong>${newLocation.name}</strong>`
          : newStatus && newStorage
              ? `To Status <strong>${newStatus.name}</strong> and Storage <strong>${newStorage.name}</strong>`
              : newLocation && newStorage
                  ? `To Location <strong>${newLocation.name}</strong> and Storage <strong>${newStorage.name}</strong>`
                  : newStatus
                      ? `To Status <strong>${newStatus.name}</strong>`
                      : newLocation
                          ? `To Location <strong>${newLocation.name}</strong>`
                          : newStorage
                              ? `To Storage <strong>${newStorage.name}</strong>`
                              : '';

  if (!newStatus && !newLocation && !newStorage) {
    appStore.value.addToast('Warning', 'Please select a valid status, location, or storage', 'danger');
    return;
  }

  let normalElements = [];
  let closedElements = [];
  changeElements.forEach((el) => {
    let changeBooking = {
      sku: el.sku,
      booking: el.current_booking?.id,
      sale: el.current_sale?.id,
      order: el.current_booking?.order_number ?? el.current_sale?.order_number,
      name: el.product_name,
      client: el.current_booking?.client ?? el.current_sale?.client,
      unit: el.id,
      status: newStatus?.id,
      location: newLocation?.id,
      storage: newStorage?.id,
      last_status: el.status?.name,
      last_location: el.location?.name,
      last_storage: el.storage?.name,
      last_order_status: el.current_booking?.order_status ?? el.current_sale?.order_status,
      rent_start: el.current_booking?.rent_start ?? el.current_sale?.fulfillment_date,
      rent_end: el.current_booking?.rent_end,
      checked: true,
      closed: Boolean((newStatus?.name === 'Almacenado' && el.current_booking) || (newStatus?.name === 'Vendido' && el.current_sale)),
    }
    if ((changeBooking.closed && isOrderLineOpen(el.current_booking || el.current_sale))) {
      closedElements.push(changeBooking);
    } else {
      normalElements.push(changeBooking);
    }
  });
  if (normalElements.length) {
    changeModalData.value.data = normalElements;
  }
  if (closedElements.length) {
    changeModalData.value.closedElements.subtitle += '<strong>Error! These Bookings are not finalized. This change is not allowed.</strong>'
    changeModalData.value.closedElements.data = closedElements;
  }
  changeModalElement.value.show();
}

const isOrderLineOpen = (ol) => {
  return ol
      && !(['Finalizado', 'Finalizado con daños', 'Devuelto', 'Cancelado', 'Reserva Cancelada']
          .includes(ol.order_status) || ol.is_cancel || ol.is_return);
}

const clearSelection = () => {
  selectedStatus.value = "";
  selectedLocation.value = "";
  selectedStorage.value = "";
  changeModalData.value = {
    closedElements: {data: [], subtitle: ''},
    data: [],
  }
  elements.value?.forEach(e => e.checked = false);
  document.querySelectorAll('input[name^="action-toggle"]').forEach((el) => el.checked = false);
  clearModalData();
}

const clearModalData = () => {
  changeModalData.value = {
    closedElements: {data: [], subtitle: ''},
    data: [],
  }
}

const changeUnitField = async () => {
  if (changeModalData.value?.note) {
    changeModalData.value.data.forEach((el) => el.note = changeModalData.value?.note);
  }
  const filtered_data = changeModalData.value?.data.filter((el) => el.checked);
  if (!filtered_data.length) {
    appStore.value.addToast('Warning', 'No booking to update', 'danger');
    return;
  }
  try {
    console.log('changing units')
    loading.value = true;
    let response = await UnitService.changeField(filtered_data);
    if (response.status === 200) {
      appStore.value.addToast('Success', "Units updated", 'success');
      clearSelection();
      loadElement();
    } else {
      appStore.value.addToast('Error', "Error while updating units", 'danger');
    }
  } catch (error) {
    console.log(error);
    appStore.value.addToast('Error', error, 'danger');
  } finally {
    loading.value = false;
  }
}

const getType = (element) => {
  if (['on', 'on_f'].includes(element.for_sale) && ['on', 'on_f'].includes(element.for_rent)) {
    return {text: 'Venta / Alquiler', color: 'text-bg-primary'};
  } else if (['on', 'on_f'].includes(element.for_sale)) {
    return {text: 'Venta', color: 'text-bg-warning'};
  } else if (['on', 'on_f'].includes(element.for_rent)) {
    return {text: 'Alquiler', color: 'text-bg-success'};
  } else {
    return {text: 'NaN', color: 'text-bg-dark'};
  }
}

const generateQRCodes = async () => {
  if (!selectedElements.value.length) {
    appStore.value.addToast('Warning', 'No unit selected', 'danger');
    return;
  }
  const codes = selectedElements.value.map(el => el.sku);
  // open new tab with the qr codes doing a POST
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = UnitService.appURL('qrgenerator/', '');
  form.target = '_blank';
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'codes';
  input.value = codes.join('\r\n');
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
}

const generateLabels = async () => {
  if (!selectedElements.value.length) {
    appStore.value.addToast('Warning', 'No unit selected', 'danger');
    return;
  }
  // open new tab with the qr codes doing a POST
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = UnitService.appURL('labelgenerator/', '');
  form.target = '_blank';
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'codes';
  input.value = selectedElements.value.map(u => u.sku).join('\r\n');
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
}

const displayBookingTooltip = (booking) => {
  if (booking) {
    return `<div class="vstack gap-1"><div>Booking: ${booking.id} Order: ${booking.order_number}</div>
    <div>Client: ${booking.client}</div>
    <div>Rent: ${longDateRange(booking.rent_start, booking.rent_end)}</div>
    <div>Shipping: ${longDateRange(booking.shipping_start, booking.shipping_end)}</div>
    <div>Blocking: ${longDateRange(booking.blocking_start, booking.blocking_end)}</div></div>`;
  }
}

const displayBookingDateTooltip = (booking) => {
  if (booking) {
    return `<div>Rent: ${longDateRange(booking.rent_start, booking.rent_end)}</div>
    <div>Shipping: ${longDateRange(booking.shipping_start, booking.shipping_end)}</div>
    <div>Blocking: ${longDateRange(booking.blocking_start, booking.blocking_end)}</div></div>`;
  }
}

watch(selectedStatus, (newValue) => {
  if (newValue) {
    const status = statuses.value.find(e => e.name === newValue);
    if (status?.to_location) {
      selectedLocation.value = locations.value.find(e => e.id === status.to_location)?.name ?? "";
    } else {
      selectedLocation.value = "";
    }
  }
});
</script>

<style scoped>
td.pill {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}
</style>